


























































































































import { NodeInfoItem } from '@/types/state'
import { Component, Vue } from 'vue-property-decorator'

import store from '@/store'
import { UserRole } from '@/enums'

@Component({})
export default class ComparisonMenu extends Vue {
    menuOpen = false

    role: UserRole = store.getters['identity/role']
    userRoleEnum = UserRole

    onMenuToggle(isOpen: boolean): void {
        this.menuOpen = isOpen
    }

    removeNode(node: NodeInfoItem):void {
        this.$store.commit("default/TOGGLE_COMPARISON", node)
    }
    
    moveDownwards(node: NodeInfoItem): void {
        this.$store.commit("default/COMPARED_NODE_MOVE_DOWN", node)
    }

    moveUpwards(node: NodeInfoItem): void {
        this.$store.commit("default/COMPARED_NODE_MOVE_UP", node)
    }

    deleteList(): void {
        this.$store.commit("default/RESET_COMPARISON_LIST")
    }

    get nodes(): Array<NodeInfoItem> {
        return this.$store.getters['default/ComparedNodes']
    }

    get titleText(): string {
        return (this.nodes.length > 0) ? 
            ((this.nodes.length == 1) ? 
                '1 EMRA-box in de vergelijking' :
                `${this.nodes.length} EMRA-boxen in de vergelijking` 
                ) :
            'Geen EMRA-boxen in de vergelijking'
    }
}
