































import { Component, Vue } from 'vue-property-decorator'

import Navigation from '@/views/default/Navigation.vue'

import ComparisonMenu from '@/views/default/ComparisonMenu.vue'

@Component({
    components: {
        Navigation,
        ComparisonMenu
    }
})
export default class DefaultMain extends Vue {
    get userRole(): string {
        return this.$store.getters['identity/role']
    }

    get isModOrAdmin(): boolean {
        return this.userRole == 'Moderator' || this.userRole == 'Admin'
    }

    get isInPresentationMode(): boolean {
        return this.$store.getters['default/IsInPresentationMode']
    }

    togglePresentationMode(): void {
        this.$store.commit('default/TOGGLE_PRESENTATION_MODE')
        this.$store.commit('detailNode/SET_PRESENTATION_MODE', this.isInPresentationMode)
        this.$store.commit('emraPageInfo/SET_PRESENTATION_MODE', this.isInPresentationMode)
        this.$store.commit('users/SET_PRESENTATION_MODE', this.isInPresentationMode)
        this.$store.commit('meters/SET_PRESENTATION_MODE', this.isInPresentationMode)
    }

    created(): void {
        this.$store.dispatch('default/init')
        this.$store.dispatch('addEmra/init')
    }
}
